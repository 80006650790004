import React, { useEffect, useRef, useState } from 'react';
import style from './Waveform.module.css'
const Waveform = React.forwardRef(({value, onInput, fixedIntensities, width, backgroundColor, emptyColor, fillColor }, ref) => {
    const canvasRef = useRef(null);
    const [minVolume,setMinVolume] = useState(10);
    const [maxVolume,setMaxVolume] = useState(110);

    function InverseLerp(min, max, val) {
        return Math.min(1, Math.max(0, (val - min) / (max - min)))
    }

    function Update(intensity){
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d', { willReadFrequently: true });

        // Get the current canvas content and move it to the left
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.putImageData(imageData, -10, 0); // Move content to the left by 5 pixels
        const normalizado = InverseLerp(minVolume, maxVolume, intensity);
        const radius = 3;
        const altura = normalizado * 5;
        const x = canvas.width - radius - 1;
        const y = canvas.height / 2;

        // Draw the rectangle
        ctx.fillStyle = backgroundColor ? backgroundColor : '#FFF';
        ctx.fillRect(canvas.width - 10, 0, radius * 2 + 20, canvas.height);

        // Set the composite operation to 'destination-out' to cut out the pill shape
        ctx.globalCompositeOperation = 'destination-out';

        // Draw the pill shape to "cut out" from the rectangle
        ctx.beginPath();
        ctx.arc(x, y + altura, radius, 0, 2 * Math.PI);
        ctx.arc(x, y - altura, radius, 0, 2 * Math.PI);
        ctx.fill();
        ctx.fillRect(x - radius, y - altura, radius * 2, altura * 2);

        // Reset composite operation back to default
        ctx.globalCompositeOperation = 'source-over';
    }
    
    React.useImperativeHandle(ref, () => ({
        Update
    }));

    useEffect(()=>{
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = backgroundColor ? backgroundColor : '#FFF';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        if(fixedIntensities){
            var max = Math.max.apply(Math, fixedIntensities);
            var min = Math.min.apply(Math, fixedIntensities);
            ctx.globalCompositeOperation = 'destination-out';
            fixedIntensities.forEach((v, i) => {
                const radius = 3;
                const altura = InverseLerp(min, max, v) * 5;
                const x = canvas.width/fixedIntensities.length * (i+0.5);
                const y = canvas.height / 2;

                ctx.beginPath();
                ctx.arc(x, y + altura, radius, 0, 2 * Math.PI);
                ctx.arc(x, y - altura, radius, 0, 2 * Math.PI);
                ctx.fill();
                ctx.fillRect(x - radius, y - altura, radius * 2, altura * 2);

            });
            ctx.globalCompositeOperation = 'source-over';
        }
        
    }, [fixedIntensities])

    return (
        <div className={style.main}>
            {value!==undefined ?
                <>
                    <input type="range" max={101} className={style.range} value={value} onInput={(e)=>{
                        if(onInput)
                            onInput(e.target.value-1);
                    }} style={{ width: `${width ? width : 190}px`, "--bck-color": (emptyColor ? emptyColor : 'gray') }} />
                    <div className={style.fillBar} style={{ width: `${value}%`, backgroundColor: (fillColor ? fillColor : '#68dce4') }}></div>
                </> : 
                <div className={style.background} style={{ width: `${width ? width : 190}px`, backgroundColor: (fillColor ? fillColor : '#68dce4') }}></div>
            }
            <canvas ref={canvasRef} width={width ? width : 190} height={24} className={style.canvas} />
        </div>
    );
});

export default Waveform;
