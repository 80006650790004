import React, { useState, useEffect, useRef } from 'react';
import style from './Chat.module.css';
import { useNavigate } from 'react-router-dom';
import LoadingDot from './LoadingDot';
import Waveform from './Waveform';
import micLogo from '../assets/mic.png'
import soundIcon from '../assets/sound.png'
import returnAudioEN from '../assets/returnEN.mp3'
import returnAudioPT from '../assets/returnPT.mp3'
import textIcon from '../assets/text.png'
import { AudioRecorder } from '../scripts/AudioRecorder';
import { ChatAPI, TranscreverAPI, getSpecifics } from '../scripts/API';

import AudioPlayer from './AudioPlayer';
import Markdown from 'react-markdown'

const Chat = ({ slidesInfo, slideIndex }) => {
	const [messages, setMessages] = useState([]);
	const [timeoutId, setTimeoutId] = useState(null);
	const [input, setInput] = useState('');
	const [recorder, setRecorder] = useState(null);
	const [muted, setMuted] = useState(JSON.parse(localStorage.getItem('muted')));
	const [messagesQueue, setMessagesQueue] = useState([]);
	const [maxSlideVisitado, setMaxSlideVisitado] = useState(0);
	const [somethingIsPlaying, setSomethingIsPlaying] = useState(null);
	const [aguardandoChat, setAguardandoChat] = useState(false);
	const [idioma, setIdioma] = useState(null);

	const navigate = useNavigate();

	
	const chatBodyRef = useRef(null); //usa para scroll automatico
	const canvasRef = useRef(null); //usa para animação ao gravar	
	
	const slideIndexRef = useRef(slideIndex);
	const mensagemRetornoDeSlide = {
		'en': "Do you have some question or comment about this slide?", 
		'pt': "Você tem alguma pergunta ou comentário sobre este slide?"} ;

	useEffect(() => {
		//ao passar de slide, pausa
		if(somethingIsPlaying){
			somethingIsPlaying.stop();
		}
		
		slideIndexRef.current = slideIndex;
		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		const newTimeoutId = setTimeout(() => {
			// Só ativa se o index se manter por um intervalo minimo
			if (slideIndex != 0) {
				if (slideIndex > maxSlideVisitado) {
					setMaxSlideVisitado(slideIndex);
					sendMessage(idioma=='en'? 'Explain the current slide, but do it as if I didnt ask':
						'Explique o slide atual, mas faça como se eu não tivesse pedido', true, true);
				}
				else if (idioma && messages[messages.length - 1].text !== mensagemRetornoDeSlide[idioma]) {
					if (muted)
						setMessages(prev => [...prev, { text: mensagemRetornoDeSlide[idioma], bot: true }]);
					else {
						setMessages(prev => [...prev, { text: mensagemRetornoDeSlide[idioma], audio:idioma=='en'?returnAudioEN:returnAudioPT, bot: true }]);
					}
				}
			}
		}, 500);

		setTimeoutId(newTimeoutId);

		return () => {
			clearTimeout(newTimeoutId);
		};
	}, [slideIndex]);

	useEffect(() => {
		if (chatBodyRef.current) {
			chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
		}
	}, [messages]);

	useEffect(() => {
		if (messagesQueue.length > 0 && !aguardandoChat && slidesInfo && slidesInfo[slideIndex]) {
			setAguardandoChat(true);
			const isSlideExp = messagesQueue[0].exp;
			const isAdendo = messagesQueue[0].ad;
			converterHistorico(messages).then(histJson => {
				ChatAPI(slidesInfo[slideIndex].id, isAdendo?null:slideIndex, histJson, !muted, isSlideExp).then(data => {
					if(data.update){
					    const base_id = window.location.pathname.split('/').pop();
						navigate(`/update/${base_id}`);
					}

					if(data.msg != ''){
							
						if(!messagesQueue[0].h || slideIndexRef.current === slideIndex){
							let result = { text: data.msg ? data.msg : '...', bot: true };
							if (data.audio)
								result.audio = decodeAudio(data.audio);
							setMessages(prev => [...prev, result]);
						}
					}
					setAguardandoChat(false);
					setMessagesQueue(prev => prev.slice(1, prev.length));
					
					if(isSlideExp && messagesQueue.length<=1 && slideIndex>0){
						sendMessage(idioma=='en'? 'What in the current slide has to do with what I asked before? Dont mention slide':
							'O que desse slide tem a ver com o que foi dito antes? Não mencione slide', true,false,true);
					}
				})
				.catch(error => {
						setAguardandoChat(false);
						setMessagesQueue(prev => prev.slice(1, prev.length));
						console.log('API call failed:', error);
					});
			});
		}
	}, [messagesQueue, slidesInfo]);

	useEffect(() => {
			getSpecifics().then(spec=>{
				setIdioma(spec.lang);				
				sendMessage('', true, true);
			})
	}, []);

	//limpa historico, aguarda promessas pendentes de transcricao e retorna JSONificado
	async function converterHistorico(historico) {
		const updatedHistorico = await Promise.all(historico.map(async (item) => {
			if (item.promessa)
				item.text = await item.promessa;
			//deixa apenas info essencial
			let e = { text: item.text, bot: item.bot };
			if (item.audio)
				e.audio = true;
			return e;
		}));
		return JSON.stringify(updatedHistorico);
	}

	function decodeAudio(audioBase64) {
		const audioData = atob(audioBase64);
		const audioArray = new Uint8Array(audioData.length);
		for (let i = 0; i < audioData.length; i++) {
			audioArray[i] = audioData.charCodeAt(i);
		}

		// Create a Blob from the audio data
		const audioBlob = new Blob([audioArray], { type: 'audio/mp3' });

		// Create an object URL for the Blob
		return URL.createObjectURL(audioBlob);
	}

	async function sendMessage(msg, hidden = false, explicacaoSlide = false, adendo=false) {
		const novo = msg.audioBlob ?
			{ audio: msg.audioUrl, promessa: TranscreverAPI(msg.audioBlob), bot: false } :
			adendo ? { text: msg, bot: false, h: true, ad: true} :
			explicacaoSlide ? { text: msg, bot: false, h: true, exp: true } :
			hidden ? { text: msg, bot: false, h: true } : { text: msg, bot: false };

		setMessagesQueue(prev => [...prev, novo]);
		setMessages(prev => [...prev, novo]);

	}

	async function InputButtonClicked() {
		if(slidesInfo && slidesInfo[slideIndex]){
			const msg = input.trim();
			if (msg) {
				sendMessage(msg);
				setInput('');
			}
			else {
				if (recorder) {
					const result = await recorder.stop();
					sendMessage(result);
					setRecorder(null);
				}
				else {
					if(somethingIsPlaying)
						somethingIsPlaying.stop();
					setRecorder(AudioRecorder((val) => {
						if (canvasRef.current)
							canvasRef.current.Update(val);
					}));
				}
	
			}
		}
	}

	const filteredMessages = messages.filter(msg => !msg.h);
	return (
		<div className={style.chat}>
			<div className={style.chatBody} ref={chatBodyRef}>
				<div className={style.messagesContainer}>
					{filteredMessages.map((msg, index) => (
						<div key={index} className={`${style.chatMessageContainer} ${msg.bot || msg.typing ? style.botMessage : style.userMessage} ${index >= filteredMessages.length - 2 ? '' : index >= filteredMessages.length - 3 ? style.old : index >= filteredMessages.length - 4 ? style.older : style.oldest}`}>
							<div>
								{
									msg.audio
										? <AudioPlayer src={msg.audio} backgroundColor={msg.bot ? '#f0ecec' : '#57d3dc'} autoPlay={msg.bot} forceAutoPlay={index==0} somethingIsPlaying={somethingIsPlaying} setSomethingIsPlaying={setSomethingIsPlaying} />
										: <div className={style.md}> <Markdown>{msg.text}</Markdown> </div> //usa tag pq as vezes bot responde em markdown
								}</div>
						</div>
					))}
					{/* {messages.length === 0 ? 
					<div className={`${style.chatMessageContainer} ${style.botMessage}`}>
						<LoadingDot />
					</div> 
					: <></>} */}
					{console.log(messagesQueue) ||
					 messagesQueue.map((_v, index) =>
						<div key={index} className={`${style.chatMessageContainer} ${style.botMessage}`}>
							<LoadingDot />
						</div>
					)}
				</div>
			</div>
			<div className={style.footer}>
				<div className={style.chatInput}>
					<div className={style.mainInputArea}>

						{recorder != null ?
							<Waveform ref={canvasRef} width={document.documentElement.clientWidth > 700 ? 170 : document.documentElement.clientWidth - 80} />
							: (slidesInfo && slidesInfo[slideIndex] ? 
								<textarea
								placeholder="Type a question..."
								value={input}
								onChange={(e) => setInput(e.target.value)}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										e.preventDefault();
										InputButtonClicked();
									}
								}}
							/>:<></>
							)							
						}
					</div>
					<button onClick={InputButtonClicked} className={`${style.inputButton} ${input == "" && !recorder ? style.audioButton : style.sendButton}`}>
						<div className={style.triangle}></div>
						<img className={style.audioIcon} src={micLogo} />
					</button>
				</div>
				<label className={style.muteButton}>
					<input type="checkbox" checked={muted} onChange={(e) => {
						setMuted(e.target.checked);
						localStorage.setItem('muted', e.target.checked);
					}} />
					<img src={soundIcon} alt="answer in audio" className={style.soundIcon} />
					<img src={textIcon} alt="answer in text" className={style.textIcon} />
				</label>
			</div>
		</div>
	);
};

export default Chat;
