import React from 'react'
import style from './LoadingDot.module.css'
const LoadingDot = () => {
  return (
    <div className={style.loadingDot}>
        <span className={style.dot}>•</span>
        <span className={style.dot}>•</span>
        <span className={style.dot}>•</span>
    </div>
  )
}

export default LoadingDot