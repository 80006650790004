export function AudioRecorder(volumeFunction=null, volumeInterval = 50) {
    let mediaRecorder;
    let audioChunks = [];
    let stream;
    let intervalId = null;

    // Start recording
    async function start() {
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorder = new MediaRecorder(stream);
        if(volumeFunction){
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const source = audioContext.createMediaStreamSource(stream);
			const analyser = audioContext.createAnalyser();
			source.connect(analyser);
			
			analyser.fftSize = 256;
			const dataArray = new Uint8Array(analyser.frequencyBinCount);

            function getAverageVolume(array) {
				const slice = array.slice(0, 20);
				let values = 0;
				let length = slice.length;
				for (let i = 0; i < length; i++) {
					values += slice[i];
				}
				return values / length;
			}
			
			intervalId = setInterval(() => {
				analyser.getByteFrequencyData(dataArray);
				const volume = getAverageVolume(dataArray);
				volumeFunction(volume);
			}, volumeInterval);
        }

        mediaRecorder.ondataavailable = (event) => {
            audioChunks.push(event.data);
        };

        mediaRecorder.start();
    }

    // Stop recording, stop the stream, and return the audio Blob and its URL
    function stop() {
        return new Promise((resolve, reject) => {
            if(intervalId){
                clearInterval(intervalId);
            }
            mediaRecorder.onstop = () => {
                const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                const audioUrl = URL.createObjectURL(audioBlob);

                // Stop all tracks to release the microphone
                stream.getTracks().forEach(track => track.stop());


                resolve({ audioBlob, audioUrl });
            };

            mediaRecorder.stop();
        });
    }

    // Immediately start recording upon creation
    start();

    // Return an object with the stop method
    return {
        stop
    };
}