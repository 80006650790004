import React, {useEffect, useState, useRef} from 'react'
import style from './Presentation.module.css'
import { getSpecifics } from '../scripts/API';

const Overlay = ({ pageInfo }) => {
    return (<div style={{ pointerEvents: "all", position: 'absolute', top: 0, left: 0, height: "100%", width: "100%" }}>
        <>
            {(pageInfo?pageInfo.links:[]).map((linkElement, i) =>
            (<a href={linkElement.url}
                style={{
                    // border: '1px dashed red',
                    position: 'absolute',
                    left: `${linkElement.rect[0] * 100}%`,
                    top: `${linkElement.rect[1] * 100}%`,
                    width: `${linkElement.rect[2] * 100}%`,
                    height: `${linkElement.rect[3] * 100}%`,
                    cursor: 'pointer',
                    pointerEvents: 'all'
                }} key={i}></a>))}
        </>
        <>
            {(pageInfo?pageInfo.videos:[]).map((videoElement, i) =>
                (<iframe
                    style={{
                        // border: '1px dashed blue',
                        position: 'absolute',
                        left: `${videoElement.rect[0] * 100}%`,
                        top: `${videoElement.rect[1] * 100}%`,
                        width: `${videoElement.rect[2] * 100}%`,
                        height: `${videoElement.rect[3] * 100}%`,
                        cursor: 'pointer',
                        pointerEvents: 'all'
                    }} key={i}
                    src={ 
                        videoElement.source == "YOUTUBE" ? "https://www.youtube.com/embed/" + videoElement.url.split('=').pop() 
                        :`https://drive.google.com/file/d/${videoElement.url.split('/d/')[1].split('/')[0]}/preview`
                    }
                    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin"
                ></iframe>
            )
            )}
        </>
    </div>);
}

const Presentation = ({slidesInfo, slideIndex, setSlideIndex}) => {
    const [value, setValue] = useState('1');
    const [firstIsLoading, setFirstIsLoading] = useState(true);
    const [secondIsLoading, setSecondIsLoading] = useState(true);
    const [mainIframe, setMainIframe] = useState(0);
    const [firstIframeIndex, setFirstIframeIndex] = useState(0);
    const [secondIframeIndex, setSecondIframeIndex] = useState(1);
    const [firstVideosRects, setFirstVideosRects] = useState([]);
    const [secondVideosRects, setSecondVideosRects] = useState([]);
    const [interactableRects, setInteractableRects] = useState([]);
    const [firstSlideLinks, SetFirstSlideLinks] = useState([]);
    const [secondSlideLinks, SetSecondSlideLinks] = useState([]);
    const [slideLinks, SetSlideLinks] = useState([]);
    
    const [slideshowID, setSlideshowID] = useState(null);

    const obter_url = () => "https://docs.google.com/presentation/d/" + slideshowID +"/embed?start=false&loop=false&delayms=3000&rm=minimal&slide=";


    useEffect(()=>{
        if(slidesInfo[firstIframeIndex]){
            setFirstVideosRects(slidesInfo[firstIframeIndex].videos);
            SetFirstSlideLinks(slidesInfo[firstIframeIndex].links);
        }
        console.log(slidesInfo);
        
        
    },[firstIframeIndex, slidesInfo ])

    useEffect(()=>{
        if(slidesInfo[secondIframeIndex]){
            setSecondVideosRects(slidesInfo[secondIframeIndex].videos);
            SetSecondSlideLinks(slidesInfo[secondIframeIndex].links);
        }
            
    },[secondIframeIndex, slidesInfo])

    useEffect(()=>{
        if(slidesInfo.length>=2){
            if(firstIframeIndex!==0){
                setFirstIframeIndex(0);
                setFirstIsLoading(true);
            }
            if(secondIframeIndex!==1){
                setSecondIframeIndex(1);
                setSecondIsLoading(true);
            }
        }
        
    },[slidesInfo])

    useEffect(()=>{
        setInteractableRects(mainIframe===0?firstVideosRects:secondVideosRects);        
        SetSlideLinks(mainIframe===0?firstSlideLinks:secondSlideLinks);
    },[mainIframe, firstVideosRects, secondVideosRects, firstSlideLinks,secondSlideLinks])

    useEffect(()=>{
        getSpecifics().then(r=>setSlideshowID(r.slideshow));
    },[])    

    useEffect(() => {
        function keyPress(e) {
            const targetTagName = e.target.tagName.toLowerCase();
            const isTyping = targetTagName === 'input' || targetTagName === 'textarea' || e.target.isContentEditable;

            if (isTyping) {
                return; // Don't change slides if the user is typing
            }
            console.log(e);
            
            if(e.code == "ArrowLeft" || e.code == "KeyA" ){
                tryUpdateIndex(slideIndex-1);
            }
            else if(e.code == "ArrowRight" || e.code == "KeyD" || e.code == "Space" ){
                tryUpdateIndex(slideIndex+1);
            }
        }
        
        window.addEventListener('keydown', keyPress);
        return () => {
            window.removeEventListener('keydown', keyPress);
        };
    }, [slideIndex])
    

    function tryUpdateIndex(n){
        if(isNaN(n)) n = 0;
        // limita para numeros possiveis
        n = Math.max(0,Math.min(slidesInfo.length-1,n));
        //atualiza se necessario
        if(value !== n+1)
            setValue(n+1);
        if(slideIndex !== n){
            setSlideIndex(n);
            //console.log('Quero '+slidesInfo[n].id+' tenho '+ firstIframeIndex,' | ',secondIframeIndex);
            if(firstIframeIndex===n){
                setMainIframe(0);
                setSecondIframeIndex(n+1);
                setSecondIsLoading(true);
            }
            else if(secondIframeIndex===n){
                setMainIframe(1);

                setFirstIframeIndex(n+1);
                setFirstIsLoading(true);
            }
            else{
                if(mainIframe === 0){
                    setFirstIframeIndex(n);
                    setSecondIframeIndex(n+1);
                }
                else{
                    setFirstIframeIndex(n+1);
                    setSecondIframeIndex(n);
                }
                setFirstIsLoading(true);
                setSecondIsLoading(true);
            }            
        }
    }
    function numberFieldInput(event){
        let n = 0 + event.target.value.replace(',','').replace('.','')
        tryUpdateIndex(n-1);
    }
    function onFormSubmit(e){
        e.preventDefault();
        tryUpdateIndex(value-1);
    }
    return (
        <div className={style.main}>
            <div className={style.slides} >
                {slideshowID?<>
                    <iframe title='slides' style={mainIframe===0&&!firstIsLoading?{}:{opacity: 0, pointerEvents:'none'}} src={obter_url(slideshowID)+(1+firstIframeIndex)} onLoad={()=>setFirstIsLoading(false)}  tabIndex={-1}></iframe>
                    <iframe title='slides' style={mainIframe===1&&!secondIsLoading?{}:{opacity: 0, pointerEvents:'none'}} src={obter_url(slideshowID)+(1+secondIframeIndex)} onLoad={()=>setSecondIsLoading(false)} tabIndex={-1}></iframe>
                </>:<></>}
                <Overlay pageInfo={slidesInfo?slidesInfo[slideIndex]:[]}/>
            </div>
            <form onSubmit={onFormSubmit} className={style.controls}>
                <button type='button' className={`${style.leftArrow} ${style.arrow} ${slideIndex===0?style.disabled:''}`} onClick={() => tryUpdateIndex(slideIndex - 1) }></button>
                <input type='number' className={style.slideIndicator} value={value} min="1" max={slidesInfo.length} onBlur={numberFieldInput} onChange={(e) => setValue(e.target.value)}/>
                <button type='button' className={`${style.rightArrow} ${style.arrow} ${slideIndex>=slidesInfo.length-1?style.disabled:''}`} onClick={() => tryUpdateIndex(slideIndex + 1)}></button>
            </form>
            {/* <iframe src="https://drive.google.com/file/d/1wQBL73qA6Y6rT1jONwEMKlW_ZvQpQo4T/preview" style={{position:'fixed',top:0}} width="640" height="480" allow="autoplay"></iframe> */}
        </div>
    )
}

export default Presentation