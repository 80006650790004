const urlAPI = 'https://wb5123u7yf.execute-api.us-east-1.amazonaws.com/api/';
//const urlAPI = 'http://127.0.0.1:8000/';

async function FetchTimes(url, options = {}, delay = 500, N = 10) {
    let attempts = 0;

    async function tryFetch() {
        try {
            const response = await fetch(url, options);
            if (!response.ok)
                throw new Error(`Failed with status ${response.status}`);
            return response;
        } catch (error) {
            attempts++;
            if (attempts >= N)
                throw new Error(`Fetch failed after ${N} attempts: ${error.message}`);
            await new Promise(resolve => setTimeout(resolve, delay));
            return tryFetch();
        }
    }

    return tryFetch();
}

async function getSpecificsInternal() {
    const base_id = window.location.pathname.split('/').pop();
    const response = await FetchTimes(urlAPI + 'specifics?id=' + base_id);

    if (!response.ok)
        throw new Error(`HTTP error! response: ${JSON.stringify(response, null, 2)}`);
    const data = await response.json()
    return data;
}
export async function getSpecifics() {
    if (window.specificIDs === undefined) {
        window.specificIDs = getSpecificsInternal();
    }
    return await window.specificIDs;
}

export async function ChatAPI(pageID, slideIndex, historicoStr, includeAudio, isSlideExp = false) {
    const spec = await getSpecifics();

    const targetAPI = isSlideExp ? 'exp' : 'chat';


    const payload =
        slideIndex === null ?
            {
                audio: includeAudio,
                input: historicoStr
            } :
            isSlideExp ? {
                slideshow: spec.slideshow,
                page: slideIndex,
                audio: includeAudio
            } : {
                slideId: spec.slideshow,
                docId: spec.infodoc,
                pageId: pageID,
                page: slideIndex + 1,
                input: historicoStr,
                audio: includeAudio
            };

    const response = await FetchTimes(urlAPI + targetAPI,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        }
    );
    if (!response.ok) {
        throw new Error('Chat endpoint error');
    }
    const j = await response.json();
    return j
}

export async function TranscreverAPI(audioBlob) {
    function blobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    const response = await FetchTimes(urlAPI + 'transcribe', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: await blobToBase64(audioBlob)
    });

    if (!response.ok)
        throw new Error(`HTTP error! response: ${JSON.stringify(response, null, 2)}`);


    const data = await response.json();
    return data.text.trim();
}

export async function SlidesInfoAPI() {
    const response = await FetchTimes(urlAPI + 'slides?id=' + (await getSpecifics()).slideshow);

    if (!response.ok)
        throw new Error(`HTTP error! response: ${JSON.stringify(response, null, 2)}`);

    const data = await response.json();

    return data.info;
}

export async function UpdateRag() {
    const response = await fetch(urlAPI + 'update?id=' + (await getSpecifics()).pasta);
    if (!response.ok)
        throw new Error(`HTTP error! response: ${JSON.stringify(response, null, 2)}`);
    const data = await response.json();
    return data;
}

export async function UpdateStatus() {
    const response = await fetch(urlAPI + 'updatestatus?id=' + (await getSpecifics()).pasta);
    if (!response.ok)
        throw new Error(`HTTP error! response: ${JSON.stringify(response, null, 2)}`);
    const data = await response.json();
    return data;
}


export async function DublarAPI(text) {
    const payload = { text: text };

    const response = await FetchTimes(urlAPI + 'dublar',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        }
    );
    if (!response.ok) {
        throw new Error('Dub endpoint error');
    }

    const j = await response.json();
    return j.audio;
}

export async function ListarApresentacoesDisponiveis() {
    const response = await FetchTimes(urlAPI + 'disponiveis');
    if (!response.ok)
        throw new Error(`HTTP error! response: ${JSON.stringify(response, null, 2)}`);
    const data = await response.json();
    return data;
}