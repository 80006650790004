import Presentation from '../components/Presentation';
import Chat from '../components/Chat';
import React, { useEffect, useState } from 'react';
import { SlidesInfoAPI } from '../scripts/API';
const Index = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [slidesInfo, setSlidesInfo] = useState([]);
  
  useEffect(()=>{
    if(slidesInfo.length===0)
      SlidesInfoAPI().then(info=>setSlidesInfo(info));
  });

  return (
    <div className="indexPage">
      <Presentation slidesInfo={slidesInfo} slideIndex={slideIndex} setSlideIndex={setSlideIndex} />
      <Chat slidesInfo={slidesInfo} slideIndex={slideIndex} />
    </div>
  );
}

export default Index