import React, { useEffect, useState } from 'react'
import { ListarApresentacoesDisponiveis } from '../scripts/API';
import style from './Disponiveis.module.css';
import { Link } from "react-router-dom";
import reloadIcon from '../assets/reload.png'
import LoadingDot from '../components/LoadingDot'

const Disponiveis = () => {
	const [apresentacoes, setApresentacoes] = useState(null);

	useEffect(() => {
		ListarApresentacoesDisponiveis().then(lista => {
			setApresentacoes(lista);
			console.log(lista);
		})
	}, [])
	return (
		<>
			<h1>Apresentações disponíveis</h1>
			<div className={style.deck}>
				{apresentacoes ?
					<>
						<div className={style.holderMain}>
							<div className={`${style.card} ${style.mainCard}`}>
								<Link to={"/"} className={style.header}>
									<h2>
										Apresentação padrão
									</h2>
								</Link>
								<div className={style.controles}>
									<a href={"https://drive.google.com/drive/u/1/folders/1vXNrtdPrz_1beOJippI085kh_JH7HwQ4"}>
										<svg focusable="false" viewBox="0 2 24 24" height="24px" width="24px" fill="currentColor"><g><path d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z"></path><path d="M0 0h24v24H0z" fill="none"></path></g></svg>
										<img className={style.docIcon} src="https://ssl.gstatic.com/docs/presentations/images/favicon-2023q4.ico" alt="slides" />
										<img className={style.docIcon} src="https://ssl.gstatic.com/docs/documents/images/kix-favicon-2023q4.ico" alt="infodoc" />
										<div className={style.tooltip}>
											Ir para pasta
										</div>
									</a>
									<Link to={"/update"} className={style.reload}>
										<img src={reloadIcon} alt="update RAG" />
										<div className={style.tooltip}>
											Atualizar
										</div>
									</Link>
								</div>
							</div>

						</div>
						{apresentacoes.map(ap => <div key={ap.id} className={style.card}>
							<Link to={"/" + ap.id} className={style.header}>
								<h2>
									{ap.name}
								</h2>
								<div className={style.tooltip}>
									Ir para apresentação
								</div>
							</Link>
							<div className={style.controles}>
								<a href={"https://drive.google.com/drive/u/1/folders/" + ap.id}>
									<svg focusable="false" viewBox="0 2 24 24" height="24px" width="24px" fill="currentColor"><g><path d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z"></path><path d="M0 0h24v24H0z" fill="none"></path></g></svg>
									{ap.slides ? <img className={style.docIcon} src="https://ssl.gstatic.com/docs/presentations/images/favicon-2023q4.ico" alt="slides" /> : <></>}
									{console.log(ap) ||
									 ap.infodoc ? <>
										<img className={style.docIcon} src="https://ssl.gstatic.com/docs/documents/images/kix-favicon-2023q4.ico" alt="infodoc" /> 
										<span style={{textDecoration:'none'}}>{ap.lang}</span>
									</>: <></>}
									<div className={style.tooltip}>
										Ir para pasta
									</div>
								</a>
								{ap.infodoc ?
									<Link to={"/update/" + ap.id} className={style.reload}>
										<img src={reloadIcon} alt="update RAG" />
										<div className={style.tooltip}>
											Atualizar
										</div>
									</Link> : <></>

								}
							</div>
						</div>
						)}
					</>
					: <LoadingDot />
				}
			</div>
		</>
	)
}

export default Disponiveis