import LoadingDot from '../components/LoadingDot';
import React, { useEffect, useState } from 'react'
import style from './Update.module.css';
import { Link } from "react-router-dom";
import { UpdateRag, UpdateStatus } from '../scripts/API';
const Update = () => {
    const [ellapsed, setEllapsed] = useState(-1);
    const [sucesso, setSucesso] = useState(false);
    const [fracasso, setFracasso] = useState(false);

    function update(){
        UpdateStatus().then(r => {
            if(r.status == 'done'){
                setSucesso(true);
            }
            else{
                console.log(r);
                
                setEllapsed(r.ellapsed);
                setTimeout(update, 10000);
            }
        }).catch(()=>{
            setFracasso(true);
        });
    }

    useEffect(() => {
        if (ellapsed < 0) {
            setEllapsed(0);
            UpdateRag().then(()=>{
                setTimeout(()=> update(false), 10000);
            })
        }
    }, []);

    const base_id = window.location.pathname.split('/').pop();
    return (
        <div className={style.main}>
            {
                sucesso == false && fracasso == false ? (
                    <>
                        <p>Atualizando dados do chatbot...</p>
                        <p>Tempo: {ellapsed}</p>
                        <LoadingDot />
                    </>
                ) : (
                    <>
                        {
                            fracasso == true ? (
                                <>
                                    <p>Falha para para atualizar!</p>
                                    <p>Tente novamente mais tarde.</p>
                                </>
                            ) : (
                                <>
                                    <p>Atualizado.</p>
                                </>
                            )
                        }
                        <Link to={"/" + base_id}>Voltar</Link>
                    </>
                )
            }

        </div>
    )
}

export default Update