import './App.css';
import React from 'react';

import Index from './pages/Index';
import Update from './pages/Update';
import Disponiveis from './pages/Disponiveis';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

function App() {

  return (
    <RouterProvider router={createBrowserRouter([
      {
        path: "/disponiveis",
        element: <Disponiveis />,
      },
      {
        path: "/update/*",
        element: <Update />,
      },
      {
        path: "*",
        element: <Index />,
      }
    ])} />
)
}

export default App;
