import React, {useState, useEffect, useRef} from 'react'
import playIcon from '../assets/play.svg'
import Waveform from './Waveform'
import style from './AudioPlayer.module.css'
const AudioPlayer = ({src, backgroundColor, autoPlay, forceAutoPlay, somethingIsPlaying, setSomethingIsPlaying}) => {
    const [percentage, setPercentage] = useState(0);
    const [duration, setDuration] = useState(0);
    const [playing, setPlaying] = useState(false);
    const [fixedIntensities, setFixedIntensities] = useState([]);
    const waveRef = useRef(null);
    const audioRef = useRef(null);

    async function getAudioIntensities(audioUrl, N) {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const response = await fetch(audioUrl);
        const arrayBuffer = await response.arrayBuffer();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    
        const channelData = audioBuffer.getChannelData(0); // Getting data from the first channel (mono or left channel in stereo)
        const sampleRate = audioBuffer.sampleRate;
        const duration = audioBuffer.duration;
        const samplesPerInterval = Math.floor(sampleRate * duration / N);
    
        const intensities = [];
    
        for (let i = 0; i < N; i++) {
            let sum = 0;
            for (let j = 0; j < samplesPerInterval; j++) {
                const sampleIndex = i * samplesPerInterval + j;
                sum += channelData[sampleIndex] ** 2;
            }
            const intensity = Math.sqrt(sum / samplesPerInterval);
            intensities.push(intensity);
        }
    
        return intensities;
    }

    function play(force){
            if(navigator.userActivation.hasBeenActive){
                if(playing){
                    setPlaying(false);
                    somethingIsPlaying.stop();                
                }else{
                    if(somethingIsPlaying){
                        somethingIsPlaying.stop();                    
                    }
                    if(setSomethingIsPlaying)
                        setSomethingIsPlaying({stop:()=>{
                            setPlaying(false);
                            audioRef.current.pause();
                            setSomethingIsPlaying(null);
                        }})
                        setPlaying(true);
                        audioRef.current.play();
                        // se tá começando com pouco tempo sobrando
                        if((100-percentage)/100*duration<0.75){
                            setPercentage(0);
                            audioRef.current.currentTime = 0;
                        }
                }
            }
            else if(force){
                setTimeout(() => {
                    play(force);
                }, 100);
            }
    }

    useEffect(()=>{
        getAudioIntensities(src, 10).then(a=>{
            setFixedIntensities(a);
        })
        if(autoPlay && somethingIsPlaying===null){
            play(forceAutoPlay);
        }
    }, [src]);

    // useEffect(()=>{
    //     if(percentage < 4 && percentage != 0){
    //         setPercentage(0);
    //     }
    // },[percentage])

    return (
    <div className={style.main}>
        <button className={style.playButton} onClick={()=>{
            if(audioRef.current){
                play();
            }
        }}>
            {playing?
                <div className={style.pauseIcon}></div>:
                <img src={playIcon} className={style.playIcon} />
            }
        </button>
        <div className={style.display}>
            <Waveform ref={waveRef} width={100} value={percentage} fixedIntensities={fixedIntensities} backgroundColor={backgroundColor} emptyColor='#FFF' fillColor='gray'
            onInput={(v)=>{
                setPlaying(false);
                setPercentage(v);
                if(audioRef.current){
                    audioRef.current.pause();
                    let atual = v/100 *duration;            
                    audioRef.current.currentTime = atual;
                }
            }}
            />
        </div>
        <audio ref={audioRef} className={style.audio} src={src} 
        onTimeUpdate={()=>{
            if(playing)
                setPercentage(Math.min(1, audioRef.current.currentTime/duration) * 100);
        }}
        onEnded={()=>{
            setSomethingIsPlaying(null);
            setPlaying(false);
            setPercentage(0);
        }}
        onDurationChange={(e)=>{
            //chrome tem um bug que duration de um audio longo aparece como infinito até ser tocado.
            // Por isso tem que fazer isso, em vez de um simples audioRef.current.duration
            let atual = e.target.duration;
            if(isNaN(atual)||!isFinite(atual)||atual == 0) atual = 10;
            setDuration(atual);
            
        }}
        ></audio>
    </div>
  )
}

export default AudioPlayer